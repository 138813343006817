function getExtensionFile(arquivo){
    return arquivo.split(".").pop().toLowerCase();
}

function canViewFile(arquivo){
    let ext = this.getExtensionFile(arquivo);
    switch (ext) {
        case 'pdf':
        case 'jpg':
            return true;
        default:
            return false;
    }
}

function getIconColor(arquivo){
    let ext = this.getExtensionFile(arquivo)
    switch (ext) {
        case 'pdf':
            return 'red';
        case 'xls':
        case 'xlsx':
        case 'csv':
            return 'teal darken-3'
        case 'doc':
        case 'docx':
            return 'indigo darken-3'
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'tiff':
        case 'bmp':
        case 'gif':
            return 'lime lighten-1';
        case 'zip':
        case 'rar':
        case 'tar':
            return 'orange darken-1'
    }
    return 'blue-grey lighten-1';
}

function getIcon(arquivo){
    let ext = this.getExtensionFile(arquivo)

    switch (ext) {
        case 'pdf':
            return 'mdi-file-pdf';
        case 'xls':
        case 'xlsx':
            return 'mdi-file-excel';
        case 'doc':
        case 'docx':
            return 'mdi-file-word';
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'tiff':
        case 'bmp':
        case 'gif':
            return 'mdi-file-image';
        case 'csv':
            return 'mdi-file-table';
        case 'zip':
        case 'rar':
        case 'tar':
            return 'mdi-folder-zip';
        default:
            return 'mdi-file';
    }
}

function bytesToHumanReadable(bytes,decimals) {
    if(bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export {
    getExtensionFile,
    canViewFile,
    getIconColor,
    getIcon,
    bytesToHumanReadable
}
<template>
    <div>
        <v-text-field background-color="white"
                      placeholder="Buscar..." hide-details
                      outlined dense clearable
                      v-model="searchValue"
                      @keydown.enter="onInputValueChanged"
                      @click:clear="clearInputValue">
            <template v-slot:append>
                <v-btn text icon @click="expand = !expand">
                    <v-icon>mdi-filter-menu</v-icon>
                </v-btn>
            </template>
        </v-text-field>
        <v-expand-transition>
            <v-card v-show="expand" tile outlined height="100" style="border-color: #c2c2c2;margin-top: -1px;">
                <v-card-text class="ma-0 pa-0">
                    <div>
                        <v-btn class="ma-2 mb-0" rounded small depressed color="success" @click="newFilter">
                            <v-icon left>mdi-plus</v-icon> Filtro
                        </v-btn>
                    </div>
                    <div class="d-flex">
                        <div class="flex-grow-1 flex-wrap">
                            <template v-for="(filterItem, index) in enabledFilterItens">
                                <div class="d-inline-flex flex-column ma-1">
                                    <span class="mb-0 px-1 caption">{{filterItem.title}}</span>
                                    <v-menu v-model="filterItem.menu" bottom right :close-on-content-click="false"
                                            transition="scale-transition" origin="top left">
                                        <template v-slot:activator="{ on }">
                                            <v-chip pill v-on="on" close @click:close="removeFilter(index)">
                                                <v-icon left v-if="filterItem.type === 'sort'">{{filterItem.data.field.ascending ? "mdi-sort-ascending" : "mdi-sort-descending"}}</v-icon>
                                                {{filterItem.data.toLabel()}}
                                            </v-chip>
                                        </template>
                                        <v-card min-width="300">
                                            <v-list>
                                                <v-list-item>
                                                    <date-form style="width: 400px;" v-if="filterItem.type === 'date'"
                                                               v-model="enabledFilterItens[index]"
                                                               @input="onFilterUpdated"
                                                               :opened.sync="enabledFilterItens[index].menu" />
                                                    <boolean-form v-if="filterItem.type === 'boolean'"
                                                                  v-model="enabledFilterItens[index]"
                                                                  @input="onFilterUpdated"
                                                                  :opened.sync="enabledFilterItens[index].menu" />
                                                    <sort-form v-if="filterItem.type ==='sort'"
                                                               v-model="enabledFilterItens[index]"
                                                               @input="onFilterUpdated"
                                                               :opened.sync="enabledFilterItens[index].menu"/>
                                                </v-list-item>
                                            </v-list>
                                        </v-card>
                                    </v-menu>
                                </div>
                            </template>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-expand-transition>
        <new-filter-dialog ref="newFilterDialog" :items="getNonEnabledFilters()" @input="onFilterSelected" />

    </div>
</template>

<script>
    // @ts-ignore
    import NewFilterDialog from "./NewFilterDialog";
    import {BooleanData} from "../../../../../models/filter/BooleanData";
    // @ts-ignore
    import SortForm from "./SortForm";
    // @ts-ignore
    import BooleanForm from "./BooleanForm";
    // @ts-ignore
    import DateForm from "./DateForm";
    import {DateData} from "../../../../../models/filter/DateData";
    import {SortData} from "../../../../../models/filter/SortData";
    import {getIconByFiscalizacaoTipo} from "../../../../../assets/FiscaizacaoUtil";


    export default {
        name: 'FilterListFragment',
        props: {
            value: null,
        },
        components: {
            NewFilterDialog,
            SortForm,
            BooleanForm,
            DateForm,
        },
        data() {
            return {
                expand: false,
                searchValue: null,
                enabledFilterItens: this.value.itens ? this.value.itens : [],
                filterItems: [
                    {
                        title: 'Data Emissão',
                        name: 'data-emissao',
                        type: 'date',
                        menu: false,
                        data: new DateData(new Date(), null, 'mensal'),
                    },
                    {
                        title: 'Data Pagamento',
                        name: 'data-pagamento',
                        type: 'date',
                        menu: false,
                        data: new DateData(new Date(), null, 'mensal'),
                    },
                    {
                        title: 'Pagas',
                        name: 'pagas',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Sim', 'Não'),
                    },
                    {
                        title: 'Lembretes',
                        name: 'lembretes',
                        type: 'boolean',
                        menu: false,
                        data: new BooleanData(true, 'Contém', 'Não Contém'),
                    },
                    {
                        title: 'Ordernar',
                        name: 'ordenar',
                        type: 'sort',
                        menu: false,
                        data: new SortData('Número', 'numero', true),
                    },
                ],
            };
        },
        watch: {
            value(newValue) {
                // @ts-ignore
                this.enabledFilterItens = this.value.itens ? this.value.itens : [];
                if (this.enabledFilterItens && this.enabledFilterItens.length > 0) {
                    this.expand = true;
                }
            },
        },
        methods: {
            getNonEnabledFilters() {
                return this._.orderBy(this.filterItems, [f => f.title]).filter((filterItem) => {
                    return !this.enabledFilterItens.find((enabledFilter) => filterItem.name === enabledFilter.name);
                });
            },
            newFilter() {
                this.$refs.newFilterDialog.openDialog();
            },
            onInputValueChanged(value) {
                this.$emit('input', this.getFilterObject());
            },
            clearInputValue() {
                this.searchValue = null;
                this.$emit('input', this.getFilterObject());
            },
            onFilterSelected(filterItem) {
                this.enabledFilterItens.push(filterItem);
                this.$emit('input', this.getFilterObject());
            },
            onFilterUpdated(filterItem) {
                this.$emit('input', this.getFilterObject());
            },
            removeFilter(index) {
                this.enabledFilterItens.splice(index, 1);
                this.$emit('input', this.getFilterObject());
            },
            getFilterObject() {
                return {
                    searchValue: this.searchValue,
                    itens: this.enabledFilterItens,
                };
            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
        },
        mounted() {
            //this.searchCriteria = this.criteriaItems[0].name;
        },
    };
</script>

<style>
.search-input .v-input__control .v-input__slot{
    padding-left: 0px !important;
    border: 0px;
}

.search-input .v-input__control .v-input__slot .v-input__prepend-inner{
    margin-top: 2px !important;
}

.search-input>.v-input__control>.v-input__slot>.v-input__append-inner>.v-input__icon{
    margin-top: 6px !important;
}

.search-input>.v-input__control>.v-input__slot>.v-input__append-inner{
    margin-top: 3px !important;
}

.search-menu{
    border-right: solid 1px rgba(0, 0, 0, 0.32);
}

.search-menu .v-input__control:before,
.search-menu .v-input__slot:before{
    display: none;
}

.search-menu .v-input__control .v-input__slot{
    box-shadow: unset;
    /*background: unset !important;*/
}

.search-menu .v-input__control .v-input__slot:after{
    display: none;
}

.search-menu>.v-input__control>.v-input__slot>.v-select__slot>.v-input__append-inner{
    margin-top: unset !important;

}
/*.search-select-input .v-text-field__details{
    margin-bottom: unset;
}*/
</style>
<template>
    <v-dialog v-model="dialog" persistent max-width="700px" scrollable>
        <v-card v-if="" min-height="300">
            <v-card-title>
                Baixar DANFEs
            </v-card-title>
            <v-card-text class="pb-0">
                <v-stepper v-model="stepperPosition" :vertical="false" class="stepper-fix" >
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <div class="d-flex justify-center">
                                <v-row v-if="captchaImage" style="max-width: 60%">
                                    <v-col class="shrink">
                                        <img v-bind:src="'data:image/jpeg;base64,'+captchaImage" />
                                    </v-col>
                                    <v-col>
                                        <v-form ref="form1" v-model="form1Valid" lazy-validation>
                                            <v-text-field v-model="captchaValue"
                                                          :rules="requiredRules"
                                                          label="Captcha"
                                                          required
                                            ></v-text-field>
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-list two-line>
                                <template v-for="(notaFiscal, index) in notasSemDanfe">
                                    <v-list-item dense :key="notaFiscal.id">
                                        <v-list-item-content>
                                            <v-list-item-title  >
                                                {{notaFiscal.numero}}
                                            </v-list-item-title>
                                            <div style="">
                                                <v-chip v-if="notaFiscal.downloadStatus === 0" x-small label color="grey lighten-1" text-color="white" class="mr-1 font-weight-bold px-2">
                                                    AGUARDANDO
                                                </v-chip>
                                                <v-chip v-if="notaFiscal.downloadStatus === 1" x-small label color="blue" text-color="white" class="mr-1 font-weight-bold px-2">
                                                    BAIXANDO...
                                                </v-chip>
                                                <v-chip v-if="notaFiscal.downloadStatus === 2" x-small label color="green" text-color="white" class="mr-1 font-weight-bold px-2">
                                                    BAIXADO
                                                </v-chip>
                                                <v-chip v-if="notaFiscal.downloadStatus === 3" x-small label color="red" text-color="white" class="mr-1 font-weight-bold px-2">
                                                    ERRO
                                                </v-chip>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider ></v-divider>
                                </template>
                            </v-list>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>

            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="login" v-if="stepperPosition === 1">
                            INICIAR
                        </v-btn>

                        <v-btn v-else text color="primary" @click="" :disabled="downloadingDanfes">
                            OK
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "BaixarDanfesDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            totalBaixado: 0,
            notasSemDanfe: [],
            form1Valid: true,
            hasErrors: false,
            captchaImage: null,
            captchaValue: null,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            stepperPosition: 1,
            downloadingDanfes: false,
        }
    },
    watch:{
        totalBaixado(value){
            if(this.downloadingDanfes && this.notasSemDanfe.length === value){
                if(!this.hasErrors){
                    this.$notify({text: 'DANFEs baixadas com sucesso!', type: 'success'});
                    this.$emit('completed');
                    this.downloadingDanfes = false;
                    this.closeDialog();
                }else{
                    this.$notify({text: 'Algumas DANFEs falharam ao serem baixadas!', type: 'error'});
                    this.downloadingDanfes = false;
                    this.totalBaixado = 0;
                }
            }
        }
    },
    methods: {
        openDialog(notasSemDanfe){
            this.dialog = true;
            this.notasSemDanfe = notasSemDanfe.map(nf => {
                nf.downloadStatus = 0;
                return this._.cloneDeep(nf)
            });
            this.showCaptchaImage()
        },
        closeDialog() {
            this.dialog = false;
            this.stepperPosition = 1;
            this.totalBaixado = 0;
            this.captchaValue = null;
            this.captchaImage = null
        },
        showCaptchaImage(){
            this.loading = true;
            this.$axios.get("/notas_fiscais/show_nfse_portal_captcha")
                .then((result) => {
                    this.loading = false;
                    this.captchaImage = result.data
                })
        },
        login(){
            if(!this.$refs.form1.validate()){
                return false
            }

            this.loading = true;
            this.$axios.get("/notas_fiscais/login_portal_nfse?captcha="+this.captchaValue)
                .then((result) => {
                    this.stepperPosition++;
                    this.loading = false;
                    this.iniciarDownloads()
                })
                .catch((error) => {
                    if(error.response.status === 404){
                        this.showCaptchaImage()
                    }
                })
        },
        async iniciarDownloads(){
            this.downloadingDanfes = true;
            for(let i = 0; i < this.notasSemDanfe.length; i++){
                await this.downloadDanfe(this.notasSemDanfe[i], (this.notasSemDanfe.length - 1) !== i);
            }
        },
        downloadDanfe(notaFiscal, hasNext){
            notaFiscal.downloadStatus = 1;
            return new Promise((resolve, reject) => {
                this.$axios.get("/notas_fiscais/download_nfse_portal_danfes?id="+notaFiscal.id + "&hasNext=" + hasNext)
                .then((response) => {
                    notaFiscal.downloadStatus = 2;
                    resolve();
                })
                .catch((error) => {
                    this.hasErrors = true;
                    notaFiscal.downloadStatus = 3;
                    resolve();
                })
                .finally(()=>{
                    this.totalBaixado++;
                })
            });
        }
    }
}
</script>

<style scoped>
    .stepper-fix{
        box-shadow: unset;
    }
    .stepper-fix .v-stepper__content{
        padding: unset;
    }
</style>
<template>
    <page title="Notas Fiscais">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div slot="menu">
            <v-menu offset-y v-if="$parent.$parent.usuario.admin || $parent.$parent.usuario.id === 14" >
                <template v-slot:activator="{ on }">
                    <v-btn outlined style="border: solid thin rgba(0, 0, 0, 0.12)" v-on="on">
                        <v-icon left>mdi-cog</v-icon>
                        <span>Ações</span>
                        <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-if="$parent.$parent.usuario.admin" @click="onUploadLoteDanfesButtonClicked" :disabled="uploadingLoteNotas">
                        <v-list-item-title>Enviar Lote de Danfe</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$parent.$parent.usuario.admin" @click="onGerarSolicitacoesDePagamentoButtonClicked">
                        <v-list-item-title>Gerar Solicitações de Pagamento</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn class="ml-2" text outlined :to="{name:'nota_fiscal_lancamentos_futuros'}">Lançamentos Futuros</v-btn>
            <input v-show="false" ref="inputFileUploadLoteDanfes" type="file" multiple
                   @input="uploadLoteDanfes" >
        </div>
        <filter-list-fragment v-model="currentFilter" @input="onFilterChanged" />

        <div class="mt-2" v-if="totalCount > 0">
            <span class="subtitle-2 font-weight-regular">{{totalCount}} resultados encontrados</span>
        </div>

        <div class="text-center" v-if="notasFicais">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>
        <v-card class="mt-3">
            <v-list two-line v-if="notasFicais && notasFicais.length > 0">
                <template v-for="(notaFiscal, index) in notasFicais">
                    <v-list-item :to="{ name: 'nota_fiscal_view', params: { id: parseInt(notaFiscal.id) }}" >
                        <v-list-item-content>
                            <v-list-item-title><span v-html="highlight(notaFiscal.numero, currentFilter.searchValue)"></span><span class="font-weight-light"> - {{notaFiscal.discriminacao}}</span></v-list-item-title>
                            <v-list-item-subtitle class="d-flex">
                                Valor Nota <b class="black--text ml-1">{{notaFiscal.valorServicos | formatNumber}}</b>
                                <template v-if="notaFiscal.dataPagamento">
                                    <div class="ml-3">
                                        Valor Pago <b class="green--text">{{notaFiscal.valorPago | formatNumber}}</b>
                                    </div>
                                    <div class="ml-3">
                                        Descontado <b class="red--text">{{notaFiscal.descontoPagamento}}%</b>
                                    </div>
                                </template>
                                <v-chip v-if="notaFiscal.lembretes.length > 0" x-small label color="yellow" text-color="" class="ml-2 mr-1 font-weight-medium px-2">
                                    <span>{{notaFiscal.lembretes.length}} {{notaFiscal.lembretes.length > 1 ? "LEMBRETES" : "LEMBRETE"}}</span>
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>
                                Emissão: <b>{{notaFiscal.createdAt | moment("DD MMM YYYY HH:mm")}}</b>
                            </v-list-item-action-text>
                            <v-list-item-action-text>
                                Pagamento: <b>{{notaFiscal.dataPagamento | moment("DD MMM YYYY")}}</b>
                            </v-list-item-action-text>
                        </v-list-item-action>
                        <v-list-item-action>
                            <v-list-item-action-text>
                                <v-btn icon color="indigo" @click.prevent="downloadDanfe(notaFiscal.id)" :disabled="notaFiscal.arquivos.length === 0">
                                    <v-icon>mdi-file-download-outline</v-icon>
                                </v-btn>
                            </v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider ></v-divider>
                </template>
            </v-list>
            <div v-else-if="loading === false && (notasFicais === null || notasFicais.length === 0)" class="text-center pa-6">
                <h1 class="display-3 mb-4">Ops!</h1>
                <span class="headline font-weight-light">Nenhum resultado encontrado!</span>
            </div>
            <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
            <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="secondary" />
        </v-card>
        <div class="text-center mt-3" v-if="notasFicais">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>

        <gerar-solicitacao-de-pagamento-dialog ref="gerarSolicitacaoDePagamentoDialog" />
    </page>
</template>

<script>
    // @ts-ignore
    import page from '../../../components/Page';
    import gql from "graphql-tag";
    import FilterListFragment from "../../financeiro/nota_fiscal/fragments/filter/FilterListFragment";
    import localforage from "localforage";
    import {BooleanData} from "../../../models/filter/BooleanData";
    import {DateData} from "../../../models/filter/DateData";
    import {SortData} from "../../../models/filter/SortData";
    import GerarSolicitacaoDePagamentoDialog from "../GerarSolicitacaoDePagamentoDialog";
    import {getExtensionFile} from "../../../assets/FileUtils";

    export default {
        name: "Index",
        components: {
            GerarSolicitacaoDePagamentoDialog,
            page,
            FilterListFragment
        },
        data() {
            return {
                offset: 20,
                page: 1,
                totalCount: 0,
                notasFicais: [],
                currentFilter: {},
                loading: true,
                uploadingLoteNotas: false,
            }
        },
        watch: {
            $route(to, from) {
                if(to.query.page === undefined){
                    this.$router.push({query:{page: '1'}});
                    return;
                }
                this.page = parseInt(to.query.page);
                if(to.query.page === from.query.page){
                    return
                }

                this.getList(this.currentFilter)
            }
        },
        methods: {
            getExtensionFile,
            pageChanged(value){
                this.$router.push({query:{page:this.page}});
            },
            getList(filter) {
                this.loading = true;

                this.$apollo.query({
                    query: gql`query($filter: SearchFilterInput!, $page: Int, $offset: Int) {
                        searchNotasFiscais(filter: $filter, page: $page, offset: $offset){
                            totalCount
                            notasFiscais:itens{
                                id
                                numero
                                dataEmissao
                                dataPagamento
                                valorServicos
                                valorPago
                                descontoPagamento
                                discriminacao
                                createdAt
                                arquivos{
                                    id
                                }
                                lembretes{
                                    id
                                }
                            }
                        }
                    }`,
                    variables: {
                        filter: this.formatFilterToRequest(filter),
                        page: this.page - 1,
                        offset: this.offset
                    },
                }).then((result) => {
                    this.notasFicais = result.data.searchNotasFiscais.notasFiscais;
                    this.totalCount = result.data.searchNotasFiscais.totalCount;
                    this.loading = false;
                });
            },
            onFilterChanged(filter) {
                this.currentFilter = filter;
                this.saveFilter(filter);
                if(this.$route.query.page == 1){
                    this.getList(this.currentFilter);
                    return
                }
                this.$router.replace({query:{page:1}});
            },
            formatFilterToRequest(filter) {
                const parsedFilter = this._.cloneDeep(filter);

                if (!parsedFilter.searchValue) {
                    parsedFilter.searchCriteria = null;
                }

                if (filter.itens) {
                    parsedFilter.itens = filter.itens.map((filterItem) => {
                        const filterItemMapped = {
                            name: filterItem.name,
                        };

                        switch (filterItem.type) {
                            case 'boolean':
                                filterItemMapped.booleanData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.booleanData.trueLabel;
                                delete filterItemMapped.booleanData.falseLabel;
                                delete filterItemMapped.booleanData.toLabel;

                                break;
                            case 'sort':
                                filterItemMapped.sortData = this._.cloneDeep(filterItem.data);
                                filterItemMapped.sortData.fieldName = filterItemMapped.sortData.field.name;
                                delete filterItemMapped.sortData.field;
                                delete filterItemMapped.sortData.toLabel;
                                break;
                            case 'date':
                                filterItemMapped.dateData = this._.cloneDeep(filterItem.data);
                                delete filterItemMapped.dateData.rangeType;
                                delete filterItemMapped.dateData.toLabel;
                                break;
                        }

                        return filterItemMapped;
                    });
                }

                return parsedFilter;
            },
            saveFilter(filter) {
                const filterParsed = this._.cloneDeep(filter);
                filterParsed.searchCriteria = null;
                filterParsed.searchValue = null;

                localforage.setItem('notas_fiscais_filter', filterParsed);
            },
            async loadFilter() {
                const savedFilter = await localforage.getItem('notas_fiscais_filter');
                if (savedFilter) {
                    savedFilter.itens.map((filterItem) => {
                        switch (filterItem.type) {
                            case 'boolean':
                                filterItem.data = new BooleanData(
                                    filterItem.data.status, filterItem.data.trueLabel, filterItem.data.falseLabel);
                                break;
                            case 'date':
                                filterItem.data = new DateData(filterItem.data.before, filterItem.data.after, filterItem.data.rangeType);
                                break;
                            case 'sort':
                                filterItem.data = new SortData(
                                    filterItem.data.field.label, filterItem.data.field.name, filterItem.data.ascending);
                                break;
                        }
                        return filterItem;
                    });
                }
                return savedFilter;
            },
            refreshList(){
                this.loading = true;
                this.getList(this.currentFilter);
            },
            highlight: function(words, query){
                if(query === ''){
                    return words;
                }
                return words.toString().replace(new RegExp(query+'$', "gi"), matchedTxt => {
                    return '<span class="highlight">' + matchedTxt + '</span>';
                });
            },
            downloadDanfe(notaFiscalId) {
                //this.downloadingDanfe = true;
                this.$axios.get('/notas_fiscais/' + notaFiscalId + '/download_danfe', {responseType: 'blob'}).then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    const fileName = response.headers['content-disposition'].split('filename=')[1];
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                    //this.downloadingDanfe = false;
                }).catch(() => {
                    //this.downloadingDanfe = false;
                    this.$dialog.error({
                        text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });

                });
            },
            onUploadLoteDanfesButtonClicked(){
                this.$refs.inputFileUploadLoteDanfes.value = "";
                this.$refs.inputFileUploadLoteDanfes.click();
            },
            uploadLoteDanfes(e){
                let file = e.target.files[0]
                this.uploadingLoteLaudos = true;

                const formData = new FormData();
                formData.append('file', file);
                this.$axios.post('/notas_fiscais/upload_danfes', formData, {
                    headers: {
                        // @ts-ignore
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    },
                }).then((result) => {
                    let arquivos = result.data
                    this.$notify({text: 'Lote enviado com sucesso!', type: 'success'});
                }).catch((error) => {
                    this.$notify({text: 'Falha ao enviar o lote!', type: 'error'});
                })
                .finally(()=>{
                    this.uploadingLoteLaudos = false
                    this.refreshList();
                })
            },
            onGerarSolicitacoesDePagamentoButtonClicked(){
                this.$refs.gerarSolicitacaoDePagamentoDialog.openDialog(this.formatFilterToRequest(this.currentFilter));
            }
        },
        mounted() {
            document.title = "Notas Fiscais - JF Assessoria Rural"
            this.loadFilter().then((filter) => {
                if(!this.$route.query.page){
                    this.$router.replace({query:{page:this.page}});
                    return;
                }else{
                    this.page = parseInt(this.$route.query.page);
                }
                if (filter) {
                    // TODO Montar o componente do filtro aqui
                    this.currentFilter = filter;
                    this.getList(this.currentFilter);
                } else {
                    this.getList(this.currentFilter);
                }
            })
        },
    }
</script>

<style>
span.highlight {
    background-color: yellow;
}
</style>
<style scoped>

</style>
<template>
    <v-dialog v-model="dialog" persistent max-width="700px" scrollable>
        <v-card v-if="" min-height="300">
            <v-card-title>
                <span class="headline" style="width: 100%">Gerar Solicitações de Pagamento</span>
                <template v-if="notasFiscaisJaPagas.length > 0">
                    <span class="red--text caption">Foram encontradas {{notasFiscaisJaPagas.length}} notas fiscais já pagas que não serão inclusas na solicitação de pagamento</span>
                    <v-switch v-model="mostrarJaPagas" dense
                              class="mt-0 grey lighten-3 pa-2" style="width: 100%"
                              hide-details label="Mostrar já pagas"></v-switch>
                </template>
                <span class="caption mt-1">{{notasFiscaisValidas.length}} notas fiscais</span>
            </v-card-title>
            <v-card-text class="pb-0">

                <v-list two-line>
                    <template v-for="(notaFiscal, index) in mostrarJaPagas ? notasFiscaisJaPagas.concat(notasFiscaisValidas) : notasFiscaisValidas">
                        <v-list-item dense :key="notaFiscal.id" style="min-height: 51px"
                                     :class="(notaFiscal.dataPagamento ? 'v-list-item-invalido' : '') + ' px-0'"
                                     v-if="notaFiscal.dataPagamento ? mostrarJaPagas : true">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-regular" >
                                    {{notaFiscal.numero}}
                                    <span class="font-weight-light"> - {{notaFiscal.discriminacao}}</span>
                                </v-list-item-title>
                                <div style="">
                                    <v-chip v-if="notaFiscal.arquivos.length === 0" x-small label color="yellow darken-2" text-color="white" class="mr-1 font-weight-bold px-2">
                                        NOTA SEM DANFE
                                    </v-chip>
                                    <v-chip v-if="notaFiscal.dataPagamento" x-small label color="red" text-color="white" class="mr-1 font-weight-bold px-2">
                                        NOTA JÁ PAGA EM {{notaFiscal.dataPagamento | moment("DD/MM/YYYY")}}
                                    </v-chip>
                                </div>
                            </v-list-item-content>
                            <v-list-item-action class="my-0 align-self-center">
                                <v-list-item-action-text style="line-height: normal">
                                    Emissão:
                                </v-list-item-action-text>
                                <v-list-item-action-text style="line-height: normal">
                                    <b>{{notaFiscal.createdAt | moment("DD MMM YYYY HH:mm")}}</b>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider ></v-divider>
                    </template>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="gerar">
                            GERAR
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <baixar-danfes-dialog ref="baixarDanfesDialog" @completed="reload" />
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import BaixarDanfesDialog from "./nota_fiscal/BaixarDanfesDialog";

export default {
    name: "GerarSolicitacaoDePagamentoDialog",
    components: {BaixarDanfesDialog},
    data() {
        return {
            dialog: false,
            loading: false,
            notasFiscais: [],
            notasFiscaisValidas: [],
            notasFiscaisJaPagas: [],
            mostrarJaPagas: false,
            currentFilter: null,
        }
    },
    methods: {
        openDialog(currentFilter){
            this.currentFilter = currentFilter;
            this.notasFiscais = []
            this.notasFiscaisValidas = []
            this.notasFiscaisJaPagas = []
            this.dialog = true;
            this.getNotasFiscais(currentFilter)
        },
        closeDialog() {
            this.dialog = false;
        },
        reload(){
            this.getNotasFiscais(this.currentFilter)
        },
        getNotasFiscais(filter) {
            this.loading = true;
            this.notasFiscaisJaPagas = [];
            this.notasFiscaisValidas = [];

            this.$apollo.query({
                query: gql`query ($filter: SearchFilterInput!, $page: Int, $offset: Int){
                    searchNotasFiscais(filter: $filter, page: $page, offset: $offset){
                        totalCount
                        notasFiscais:itens{
                            id
                            numero
                            discriminacao
                            dataEmissao
                            createdAt
                            dataPagamento
                            arquivos{
                                id
                            }
                        }
                    }
                }`,
                variables: {
                    filter: filter,
                    page: 0,
                    offset: 10000
                }
            }).then((result) => {
                this.notasFiscais = result.data.searchNotasFiscais.notasFiscais;
                this.notasFiscais.forEach(notaFiscal => {
                    if(notaFiscal.dataPagamento){
                        this.notasFiscaisJaPagas.push(notaFiscal)
                    }else{
                        this.notasFiscaisValidas.push(notaFiscal)
                    }
                });
                this.loading = false;
            });
        },
        gerar(){
            let notasSemDanfe = this.notasFiscaisValidas.filter(notaFiscal => notaFiscal.arquivos.length === 0)
            if(notasSemDanfe.length > 0){
                this.$dialog.confirm({
                    title: 'Atenção',
                    text: 'Algumas notas fiscais não possue o DANFE.\nDeseja baixar as DANFEs faltantes?',
                    actions: {
                        false: 'Cancelar',
                        yes: {
                            text: 'Sim',
                            color: 'primary',
                        },
                    }
                }).then((result) => {
                    if (result) {
                        this.baixarDanfes(notasSemDanfe);
                    }
                })
                return;
            }

            this.loading = true;
            let notasFiscaisIds = this.notasFiscaisValidas.map(notaFiscal => notaFiscal.id);

            this.$axios.post("notas_fiscais/download_solicitacao_pagamento", notasFiscaisIds, {responseType: 'blob'})
                .then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    const fileName = response.headers['content-disposition'].split('filename=')[1];
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                })
                .catch((error) => {
                    this.$dialog.error({
                        text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });
                }).finally(()=>{
                    this.loading = true;
                    this.closeDialog();
                })
        },
        baixarDanfes(notasSemDanfe){
            this.$refs.baixarDanfesDialog.openDialog(notasSemDanfe);
        }
    }
}
</script>

<style scoped>
.v-list-item-invalido{
    background-color: #f9f9f9;
}
.v-list-item-invalido .v-list-item__title,
.v-list-item-invalido .v-list-item__subtitle,
.v-list-item-invalido .v-list-item__action-text{
    opacity: 0.7;
}
</style>